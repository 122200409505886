.privacy ol {
  counter-reset: item;
}

.privacy ol > li {
  margin-left: 8px;
}

.privacy li {
  display: block;
}

.privacy li::before {
  content: counters(item, ".");
  counter-increment: item;
  margin-right: 6px;
}

.privacy ol > li::before {
  font-weight: bold;
}

.privacy .underline {
  text-decoration: underline;
}

.privacy li {
  margin-top: 16px;
}

.privacy * {
  line-height: 150%;
}

.privacy .roman {
  counter-reset: item;
}

.privacy .roman > li::before {
  content: "(" counter(item, lower-roman) ")";
}

.privacy h1,
.privacy h2,
.privacy h3,
.privacy h4,
.privacy h5,
.privacy h6,
.privacy p,
.privacy span,
.privacy li {
  font-family: Calibri, sans-serif;
  font-size: 0.95rem;
}

.privacy ol:last-child {
  margin-bottom: 32px;
}

.privacy ol > li .left-space {
  display: flex;
}

.privacy .small-title {
  font-size: 0.95rem;
}

.privacy .list-title::before {
  font-size: 0.95rem;
}

.privacy .link {
  text-decoration: underline;
  color: blue;
}

.privacy .title {
  font-size: 1rem;
}

.privacy .attention {
  font-size: 0.95rem;
  margin-bottom: 16px;
}

.privacy table {
  margin: 12px 0px;
  border: 1px solid black;
}

.privacy tr {
  border: 1px solid black;
}

.privacy td {
  padding: 2px 8px;
}

.privacy .date {
  margin-bottom: 16px;
}

.privacy .line {
  margin: 24px 0px;
}

/*----- For thing bigger than mobile -----*/
@media (min-width: 640px) {
  .privacy ol {
    counter-reset: item;
  }

  .privacy ol > li {
    margin-left: 24px;
  }

  .privacy li {
    display: block;
  }

  .privacy li::before {
    content: counters(item, ".");
    counter-increment: item;
    margin-right: 12px;
  }

  .privacy ol > li::before {
    font-weight: bold;
  }

  .privacy .underline {
    text-decoration: underline;
  }

  .privacy li {
    margin-top: 16px;
  }

  .privacy * {
    line-height: 150%;
  }

  .privacy .roman {
    counter-reset: item;
  }

  .privacy .roman > li::before {
    content: "(" counter(item, lower-roman) ")";
  }

  .privacy h1,
  .privacy h2,
  .privacy h3,
  .privacy h4,
  .privacy h5,
  .privacy h6,
  .privacy p,
  .privacy span,
  .privacy li {
    font-family: Calibri, sans-serif;
    font-size: 0.95rem;
  }

  .privacy ol:last-child {
    margin-bottom: 32px;
  }

  .privacy ol > li .left-space {
    display: flex;
  }

  .privacy .small-title {
    font-size: 20px;
  }

  .privacy .list-title::before {
    font-size: 20px;
  }

  .privacy .link {
    text-decoration: underline;
    color: blue;
  }

  .privacy .title {
    font-size: 1.5rem;
  }

  .privacy .attention {
    font-size: 1.2rem;
    margin-bottom: 16px;
  }

  .privacy table {
    margin: 12px;
    border: 1px solid black;
  }

  .privacy tr {
    border: 1px solid black;
  }

  .privacy td {
    padding: 4px 12px;
  }

  .privacy .date {
    margin-bottom: 16px;
  }

  .privacy .line {
    margin: 24px 0px;
  }
}
