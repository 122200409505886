@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --primary: #10cf55;
  --secondary-button-bg: #212823;
  --tertiary-button-bg: #171717;
}

body {
  font-family: "Inter", sans-serif !important;
}

@layer base {
  *,
  *::before,
  *::after {
    @apply box-border;
  }

  * {
    @apply m-0;
  }

  html,
  body {
    @apply h-full overflow-auto scroll-smooth;
  }

  img {
    @apply inline;
  }

  picture,
  video,
  canvas {
    @apply block max-w-full;
  }

  /* For react-icons align correctly */
  svg {
    @apply inline-block;
    vertical-align: -0.125em;
  }

  #root {
    @apply h-full;
  }
  #root,
  #__next {
    @apply isolate;
  }
}

@layer components {
  .authenticate-wrapper {
    @apply flex flex-1 flex-col min-h-0 h-full items-center;
  }
}

/* For react-icons align correctly */
.ant-btn > svg:not(:only-child) {
  margin-right: 4px;
}

/* For override react player */
.react-player__preview {
  border-radius: 20px !important;
}

p {
  margin-bottom: unset !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  z-index: 10;
  color: white !important;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  z-index: 10;
  color: white !important;
}
