.quill .ql-container {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid #434343;
  word-break: break-all;
}

.quill .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.quill .ql-editor {
  height: 300px;
}
